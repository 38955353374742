import * as React from 'react'
import { graphql } from 'gatsby'
import { QueryClient, QueryClientProvider } from 'react-query'

import SEO from '../../components/seo'

import IndicatorLayout from '../../components/layouts/indicator-layout'

import { IndicatorContextProvider } from '../../components/indicators/indicator-context'

import IndicatorHeader from '../../components/indicators/indicator-header'
import VizContainer from '../../components/indicators/viz-container'
import SideBar from '../../components/indicators/side-bar'

import './indicator.scss'

const queryClient = new QueryClient()

const IndicatorPage = ({ data }) => {
    const metadata = data.allMetadataCsv.nodes[0]
    return (
        <QueryClientProvider client={queryClient}>
            <IndicatorContextProvider>
                <IndicatorLayout>
                    <SEO />
                    {metadata && (
                        <>
                            <IndicatorHeader category={metadata.Topic_area} name={metadata.Indicator_short_name} />
                            <div className="indicator-container">
                                <VizContainer metadata={metadata} />
                                <SideBar metadata={metadata} />
                            </div>
                        </>
                    )}
                </IndicatorLayout>
            </IndicatorContextProvider>
        </QueryClientProvider>
    )
}

export default IndicatorPage

export const query = graphql`
    query MetadataQuery($id: String) {
        allMetadataCsv(filter: { id: { eq: $id }, Topic_area: { nin: "Neighborhoods" } }) {
            nodes {
                Decimals
                Data_source
                Data_Source___link
                Comparison_against_population
                Comparison_data
                Geography
                Indicator_code
                Indicator_long_name
                Indicator_notes
                Indicator_short_name
                Indicator_comparison_name
                Margin_of_error
                Point_in_time
                Time_series
                Primary_tooltip
                Re_focused_tooltip
                Comparison_tooltip
                Topic_area
                Unit_of_measurement
                Sidebar_content_1
                Sidebar_content_2
                Sidebar_content_3
                Sidebar_content_4
                Sidebar_content_5
                Sidebar_title_1
                Sidebar_title_2
                Sidebar_title_3
                Sidebar_title_4
                Sidebar_title_5
                Year___Point_in_time
                NEW_re_focused_tooltip
            }
        }
    }
`
