import React, { createContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import { useQuery } from 'react-query'

import { useIndicators } from '../../hooks/use-indicators'

import { dataRequest, defaultFetchData, queryNotComplete, getIndicatorData, getBarName, getDecimals } from '../../utils/helper'
import { ToggleOptions, MigrationToggleOptions, QueryParam, BarchartModes, CulturalCommunities } from '../../utils/enum'
import { QP_DEFAULT, MIGRATION_RATES_TOOLTIPS } from '../../utils/const'
import { valueFormatter } from '../../utils/format'

export const IndicatorContext = createContext()
export const IndicatorContextProvider = (props) => {
    const { children } = props
    const indicators = useIndicators()

    const [view, setView] = useQueryParam(QueryParam.View, withDefault(StringParam, QP_DEFAULT[QueryParam.View]))
    // const [barchartMode, setBarchartMode] = useQueryParam(QueryParam.BarChartMode, withDefault(StringParam, QP_DEFAULT[QueryParam.BarChartMode]))
    const [barchartMode, setBarchartMode] = useState(BarchartModes.Regular)
    const [indicator, setIndicator] = useState('')
    const [indicatorMetadata, setIndicatorMetadata] = useState(null)
    const [barchartData, setBarchartData] = useState([])
    const [stackedBarchartData, setStackedBarchartData] = useState([])
    const [barSelected, setBarSelected] = useQueryParam(QueryParam.CulturalCommunity, withDefault(StringParam, QP_DEFAULT[QueryParam.CulturalCommunity]))
    const [diffBarchartData, setDiffBarchartData] = useState([])
    const [lineChartData, setLinechartData] = useState([])
    const [lineSelected, setLineSelected] = useState('')
    const [defaultLineSelected, setDefaultLineSelected] = useState('')
    const [certificateAchieversCheckboxStatus, setCertificateAchieversCheckboxStatus] = useState(false)
    const [focusColorCommCheckboxStatus, setFocusColorCommCheckboxStatus] = useState(false)
    const [downloadImage, setDownloadImage] = useState(false)

    //Queries
    const comparisonRequest = dataRequest(ToggleOptions.Comparison)
    const comparisonQuery = useQuery('comparisonQuery', () => defaultFetchData(comparisonRequest))
    const timeRequest = dataRequest(ToggleOptions.Time)
    const timeQuery = useQuery('timeRequest', () => defaultFetchData(timeRequest))

    //Bar chart data
    useEffect(() => {
        if (
            !queryNotComplete(comparisonQuery) &&
            indicator &&
            indicatorMetadata &&
            (view === ToggleOptions.Comparison || view === MigrationToggleOptions.Net || view === MigrationToggleOptions.InOut)
        ) {
            //Set the default view for migration rates indicators
            if (indicator === 'sb3' && view === ToggleOptions.Comparison) {
                setView(MigrationToggleOptions.InOut)
            }
            const indicatorColHeader = getIndicatorData(indicator)
            let data = generateChartData(comparisonQuery?.data, false, indicatorMetadata, indicatorColHeader)
            //Remove white group from the list
            if (focusColorCommCheckboxStatus) {
                let filteredData = data.filter((d) => d.parentCategory !== 'White')
                data = [...filteredData]
            }
            //Grab comparison data and add it to the previous data
            if (indicatorMetadata.Comparison_against_population === 'TRUE') {
                const indicatorColHeaderComparison = indicatorMetadata.Comparison_data
                const childCategoryName = indicators.find((d) => d.Indicator_code === indicatorColHeaderComparison).Indicator_comparison_name
                const comparisonData = generateChartData(comparisonQuery?.data, false, indicatorMetadata, indicatorColHeaderComparison, childCategoryName, true)
                const updatedData = data.map((d) => {
                    return { ...d, childCategory: getBarName(indicator), parent: true, additionalData: comparisonData.find((dd) => d.parentCategory === dd.parentCategory)?.additionalData } //To be able to draw bar with full opacity
                })

                data = [...updatedData, ...comparisonData]
                //Remove white group from the list
                if (focusColorCommCheckboxStatus) {
                    let filteredData = data.filter((d) => d.parentCategory !== 'White')
                    data = [...filteredData]
                }
            }
            //hc4 indicator
            if (certificateAchieversCheckboxStatus) {
                const indicatorColHeaderAdditional = 'hc4_cert'
                const additionalData = generateChartData(comparisonQuery?.data, false, indicatorMetadata, indicatorColHeaderAdditional)
                data = data.map((d) => {
                    const match = additionalData.find((ad) => ad.childCategory === d.childCategory)
                    const updatedValues = match ? { additionalValue: match.value } : null
                    return match ? { ...d, ...updatedValues } : d
                })
            }
            //sb3 indicator
            if (indicator === 'sb3' && view === MigrationToggleOptions.InOut) {
                let inflowDataDomestic = generateChartData(
                    comparisonQuery?.data,
                    false,
                    { Unit_of_measurement: indicatorMetadata.Unit_of_measurement, Margin_of_error: indicatorMetadata.Margin_of_error },
                    'sb3_inflow_dom_rate',
                )
                let inflowDataInternational = generateChartData(
                    comparisonQuery?.data,
                    false,
                    { Unit_of_measurement: indicatorMetadata.Unit_of_measurement, Margin_of_error: indicatorMetadata.Margin_of_error },
                    'sb3_inflow_int_rate',
                )

                let outflowData = generateChartData(
                    comparisonQuery?.data,
                    false,
                    { Unit_of_measurement: indicatorMetadata.Unit_of_measurement, Margin_of_error: indicatorMetadata.Margin_of_error },
                    'sb3_outflow_rate',
                    '',
                    false,
                    true,
                )
                data = [...outflowData, ...inflowDataDomestic, ...inflowDataInternational]
            }

            if (indicator === 'sb3' && view === MigrationToggleOptions.Net) {
                data = generateChartData(comparisonQuery?.data, false, indicatorMetadata, 'sb3_net_rate')
            }

            const sortedData = _.orderBy(data, ['parentCategory'], ['asc']) //Sorted alphabetically
            if (indicator === 'sb3') {
                setStackedBarchartData(sortedData)
            } else {
                setBarchartData(sortedData)
            }
        }
    }, [!queryNotComplete(comparisonQuery), indicator, indicatorMetadata, view, certificateAchieversCheckboxStatus, focusColorCommCheckboxStatus])

    //Line chart data
    useEffect(() => {
        if (!queryNotComplete(timeQuery) && indicator && indicatorMetadata && indicatorMetadata.Point_in_time === 'TRUE' && view === ToggleOptions.Time) {
            const indicatorColHeader = getIndicatorData(indicator)
            let data = generateChartData(timeQuery?.data, true, indicatorMetadata, indicatorColHeader)
            //Set default cultural community
            if (indicator === 'ao4' || indicator === 'ed3') {
                setDefaultLineSelected(CulturalCommunities.MajorityPOC)
            } else {
                const match = data.find((d) => d.childCategory === CulturalCommunities.BlackAncestry)
                if (match) {
                    setDefaultLineSelected(CulturalCommunities.BlackAncestry)
                } else {
                    setDefaultLineSelected(CulturalCommunities.Black)
                }
            }

            //Remove white group from the list
            if (focusColorCommCheckboxStatus) {
                let filteredData = data.filter((d) => d.parentCategory !== 'White')
                data = [...filteredData]
            }
            setLinechartData(data)
        }
    }, [!queryNotComplete(timeQuery), indicator, indicatorMetadata, view, focusColorCommCheckboxStatus])

    //Compute data for bar chart in focused mode
    useEffect(() => {
        if (!queryNotComplete(comparisonQuery) && barSelected && barchartMode === BarchartModes.Focused) {
            const indicatorColHeader = getIndicatorData(indicator)
            let data = generateChartData(comparisonQuery?.data, false, indicatorMetadata, indicatorColHeader)
            if (focusColorCommCheckboxStatus) {
                let filteredData = data.filter((d) => d.parentCategory !== 'White')
                data = [...filteredData]
            }
            const referenceData = data.find((d) => d.childCategory === barSelected)
            let differenceData = data.map((d) => {
                const diff = d.value - referenceData?.value
                const referencePoint = d.childCategory === barSelected
                return { ...d, diffValue: Number(diff.toFixed(2)), referencePoint }
            })
            differenceData = _.orderBy(differenceData, ['parentCategory'], ['asc']) //Sorted alphabetically
            setDiffBarchartData(differenceData)
        }
    }, [!queryNotComplete(comparisonQuery), barSelected, barchartMode, focusColorCommCheckboxStatus])

    return (
        <IndicatorContext.Provider
            value={{
                view,
                setView,
                indicator,
                setIndicator,
                indicatorMetadata,
                setIndicatorMetadata,
                barchartData,
                setBarchartData,
                barchartMode,
                setBarchartMode,
                barSelected,
                setBarSelected,
                diffBarchartData,
                setDiffBarchartData,
                lineChartData,
                setLinechartData,
                lineSelected,
                setLineSelected,
                defaultLineSelected,
                setDefaultLineSelected,
                certificateAchieversCheckboxStatus,
                setCertificateAchieversCheckboxStatus,
                stackedBarchartData,
                setStackedBarchartData,
                focusColorCommCheckboxStatus,
                setFocusColorCommCheckboxStatus,
                downloadImage,
                setDownloadImage,
            }}
        >
            {children}
        </IndicatorContext.Provider>
    )
}

//Generate data object for bar chart
const generateChartData = (queryData, temporalData = false, metadata, indicatorColHeader, rowName = '', comparativeData = false, negativeData = false) => {
    const unit = metadata.Unit_of_measurement
    const errorBar = metadata.Margin_of_error
    //Special case for migration indicators
    const decimals = metadata.Indicator_code !== 'sb3' ? metadata.Decimals : getDecimals(indicatorColHeader)
    let data = queryData
        .map((row) => {
            let additionalData = null
            //Data for tooltips
            if (indicatorColHeader === 'demog_share') {
                additionalData = row['demog_num']
            }
            if (comparativeData) {
                additionalData = valueFormatter(row[indicatorColHeader], unit)
            }

            const childCategoryName = rowName ? rowName : row.Cultural_Community
            const formattedValue = valueFormatter(row[indicatorColHeader], unit, decimals)
            const value = !negativeData ? formattedValue : -formattedValue
            const tooltip = MIGRATION_RATES_TOOLTIPS.find((d) => d.id === indicatorColHeader)?.label
            const baseObject = { childCategory: childCategoryName, parentCategory: row.Race, value, comparativeBar: comparativeData, additionalData, indicator: indicatorColHeader, tooltip }
            const extendedObject =
                errorBar === 'TRUE' ? { upperValue: valueFormatter(row[`${indicatorColHeader}_upper`], unit), lowerValue: valueFormatter(row[`${indicatorColHeader}_lower`], unit) } : null
            const date = temporalData ? { date: Number(row.Year) } : null
            return { ...baseObject, ...extendedObject, ...date }
        })
        .filter((d) => d.value && d.parentCategory !== 'All' && d.parentCategory !== 'Unknown') //Remove empty values and All category
    return data
}
