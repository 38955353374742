import React from 'react'
import { scaleLinear } from 'd3-scale'

import { useChartDimensions } from '../../../utils/dimensions'
import { ColorLines, COLOR_MAP_VIZ } from '../../../utils/theme'
import { BarchartModes, Units } from '../../../utils/enum'
import { PERCENT_TICK_VALUES } from '../../../utils/const'
import { getAxisFormatters } from '../../../utils//format'
import { computeDataDomain } from '../../../utils/axis'

import Axis from '../../common/svg/axis'
import Line from '../../common/svg/line'
import Bar from './bar'

import './bar-chart.scss'

const MARGIN = {
    top: 20,
    right: 20,
    bottom: 10,
    left: 20,
}

const BarChart = ({ data, errorBars = false, unit = 'percent', barSelected, barSelection, mode = 'regular', canClick, domain, tooltip, decimals }) => {
    const [barChartRef, dimensions] = useChartDimensions(MARGIN)

    const chartWidth = dimensions.width - MARGIN.right - MARGIN.left
    const height = dimensions.height
    const xAxisScaleDomain = domain.length > 0 ? domain : computeDataDomain(data, unit)
    const xAxisScale = scaleLinear().domain(xAxisScaleDomain).range([0, chartWidth]).nice()
    let tickValues = []

    //To handle special cases for indicators
    if (unit === Units.percent && domain.length === 0) {
        tickValues = PERCENT_TICK_VALUES
    } else if (unit === Units.percent && domain.length > 0) {
        tickValues = xAxisScale.ticks()
    } else {
        tickValues = xAxisScale.ticks()
    }

    const referencePoint = mode !== BarchartModes.Regular ? data.find((d) => d.referencePoint)?.value : null

    //Transform property is not supported by html2canvas so I had to add the MARGINs to every coordinate
    return (
        <>
            <div className="bar-chart">
                <div className="bar-chart-axis-container">
                    <svg className="bar-chart-axis">
                        <Axis scale={xAxisScale} position="top" offset={[MARGIN.right, MARGIN.top]} tickValues={tickValues} tickFormat={getAxisFormatters(unit)} />
                    </svg>
                    <svg className="bar-chart-axis-lines">
                        {tickValues.map((value) => {
                            const lineData = [
                                [xAxisScale(value) + MARGIN.left, 0],
                                [xAxisScale(value) + MARGIN.left, height],
                            ]
                            return <Line key={`line-${value}`} data={lineData} styles={{ strokeWidth: 1, opacity: 1, stroke: ColorLines.LightGray }} />
                        })}
                        {BarchartModes.Focused && referencePoint && (
                            <Line
                                data={[
                                    [xAxisScale(referencePoint) + MARGIN.left, 0],
                                    [xAxisScale(referencePoint) + MARGIN.left, height],
                                ]}
                                styles={{ strokeWidth: 1, stroke: ColorLines.DarkBlue, strokeDasharray: '5,5', transition: 'all 0.4s ease-in' }}
                            />
                        )}
                    </svg>
                </div>

                <div className="bar-chart-bars" ref={barChartRef} style={{ top: `${MARGIN.top}px` }}>
                    {data.map((row, idx) => {
                        return (
                            <React.Fragment key={`bar-${idx}`}>
                                {!canClick && !row.comparativeBar && row.indicator !== 'sb3_net_rate' && row.indicator !== 'hc4_degree' && (
                                    <div className="labels" style={{ left: `${MARGIN.left}px`, color: COLOR_MAP_VIZ[row.parentCategory], fontWeight: 'bold' }}>
                                        {row.parentCategory}
                                    </div>
                                )}
                                <Bar
                                    data={row}
                                    config={MARGIN}
                                    scale={xAxisScale}
                                    barSelected={barSelected}
                                    barSelection={barSelection}
                                    mode={mode}
                                    referencePoint={referencePoint}
                                    errorBars={row.additionalValue ? false : errorBars}
                                    unit={unit}
                                    canClick={canClick}
                                    tooltip={tooltip}
                                    decimals={decimals}
                                />
                            </React.Fragment>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default BarChart
