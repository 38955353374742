import * as React from 'react'
import Dialog from '@mui/material/Dialog'

const ModalDialog = ({ onClose, open, children }) => {
    return (
        <Dialog onClose={onClose} open={open} maxWidth="md">
            {children}
        </Dialog>
    )
}

export default ModalDialog
