import * as React from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

const BasicSelect = ({ optionsList, label, selection, onChange }) => {
    return (
        <Box sx={{ minWidth: 250 }}>
            <FormControl fullWidth size="small">
                <InputLabel id="simple-select-label">{label}</InputLabel>
                <Select labelId="simple-select-label" id="simple-select" value={selection} label={label} onChange={onChange}>
                    {optionsList.map((option) => {
                        return (
                            <MenuItem key={`menu-item-${option}`} value={option}>
                                {option}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Box>
    )
}

export default BasicSelect
