import * as React from 'react'

import Accordion from '../controls/accordion'

import './side-bar.scss'

const SideBar = ({ metadata }) => {
    const {
        Topic_area,
        Sidebar_content_1,
        Sidebar_content_2,
        Sidebar_content_3,
        Sidebar_content_4,
        Sidebar_content_5,
        Sidebar_title_1,
        Sidebar_title_2,
        Sidebar_title_3,
        Sidebar_title_4,
        Sidebar_title_5,
    } = metadata

    const formattedData = [
        { title: Sidebar_title_1, content: Sidebar_content_1 },
        { title: Sidebar_title_2, content: Sidebar_content_2 },
        { title: Sidebar_title_3, content: Sidebar_content_3 },
        { title: Sidebar_title_4, content: Sidebar_content_4 },
        { title: Sidebar_title_5, content: Sidebar_content_5 },
    ]
    return (
        <aside className="side-bar">
            <Accordion list={formattedData} category={Topic_area} />
        </aside>
    )
}

export default SideBar
