import * as React from 'react'
import CloseIcon from '@mui/icons-material/Close'

import { ToggleOptions, MigrationToggleOptions } from '../../utils/enum'

import ComparisonViewInstructions1 from '../../images/ComparisonView_instructions_1.png'
import ComparisonViewInstructions2 from '../../images/ComparisonView_instructions_2.png'
import InOutMigrationInstructions from '../../images/InOutMigration_instructions.png'
import NetMigrationInstructions from '../../images/NetMigration_instructions.png'

import TimeViewInstructions from '../../images/TimeView_instructions.png'

import './chart-instructions.scss'

const ChartInstructions = ({ view, onClose }) => (
    <div className="chart-instructions-container">
        <div className="close-icon" onClick={onClose}>
            <CloseIcon fontSize="medium" />
        </div>

        {view === ToggleOptions.Comparison && (
            <div>
                <span className="instructions-text">
                    <strong>The current view</strong> displays data for each community. The vertical bar marks each estimated value, horizontal lines show confidence intervals, and you can see a
                    description of the data by hovering over each bar.
                </span>
                <img src={ComparisonViewInstructions1} />
                <span className="instructions-text">
                    <strong>Clicking on any bar sets that community as the reference point</strong>. All other bars are redrawn to show the difference from that reference. You can click on another bar
                    to establish a new reference point.
                </span>
                <img src={ComparisonViewInstructions2} />
            </div>
        )}
        {view === MigrationToggleOptions.InOut && (
            <div>
                <span className="instructions-text">
                    <strong>In and out migration</strong> shows different migration flows to and from the Minneapolis-St. Paul region. The orange bars on the left show out-migration per 1,000 people,
                    and the blue bars on the right show in-migration per 1,000 people.
                </span>
                <img src={InOutMigrationInstructions} />
            </div>
        )}
        {view === MigrationToggleOptions.Net && (
            <div>
                <span className="instructions-text">
                    <strong>Net domestic migration</strong> shows net migration rates for each community (combining domestic in-migration and domestic out-migration).
                </span>
                <img src={NetMigrationInstructions} />
            </div>
        )}
        {view === ToggleOptions.Time && (
            <div>
                <span className="instructions-text">
                    <strong>The change over time view</strong> lets you see how the data has changed over the last 5 years. Hover over the chart to see each community’s data over time. Use the
                    dropdown menu on the upper right to “pin” a line in place.
                </span>
                <img src={TimeViewInstructions} />
            </div>
        )}
    </div>
)

export default ChartInstructions
