import React, { useEffect } from 'react'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'

import { BarchartModes } from '../../../utils/enum'
import { unitFormatter } from '../../../utils/format'
import { COLOR_MAP_VIZ, ComparisonColors } from '../../../utils/theme'
import { OPACITY } from '../../../utils/const'
import { isChildren } from '../../../utils/helper'

import './bar.scss'

const BAR_HEIGHT = 16
const DIAMOND_WIDTH = 0.5 // Percentage
const patternGenerator = (category) => {
    const color = COLOR_MAP_VIZ[category]
    return `repeating-linear-gradient(120deg, ${color}, ${color} 1px, white 1px, white 4px)`
}

const Bar = ({ data, config, scale, mode, barSelected, barSelection, referencePoint, errorBars, unit, canClick, tooltip, decimals }) => {
    useEffect(() => {
        ReactTooltip.rebuild()
    })

    let backgroundColor = COLOR_MAP_VIZ[data.parentCategory]

    //Bar styles
    const barWidth = mode === BarchartModes.Regular ? transformToPercent(data.value, scale, mode) : transformToPercent(data.diffValue, scale, mode)
    let opacity = isChildren(data) ? OPACITY.childrenOpacity : OPACITY.parentOpacity
    //When there is comparison data
    if (data.parent) {
        opacity = OPACITY.parentOpacity
    }

    let barStyles = { width: `${barWidth}%`, backgroundColor, opacity, height: data.comparativeBar ? BAR_HEIGHT * 0.5 : BAR_HEIGHT }
    //Additional bar styles
    let additionalBarStyles = data.additionalValue
        ? {
              width: `${transformToPercent(data.additionalValue, scale, mode)}%`,
              height: BAR_HEIGHT,
              borderLeft: '1px solid white',
              background: patternGenerator(data.parentCategory),
          }
        : null

    //Lower error bar styles
    const lowerErrorBarWidth = errorBars ? Math.abs(barWidth - transformToPercent(data.lowerValue, scale, mode)) : null
    let lowerErrorBarStyles =
        errorBars && !data.comparativeBarm ? { height: '2px', border: 'none', backgroundColor, width: `${lowerErrorBarWidth}%`, left: `-${lowerErrorBarWidth + DIAMOND_WIDTH * 0.5}%` } : null

    //Diamond styles
    const diamondPosition = errorBars ? lowerErrorBarWidth + DIAMOND_WIDTH * 0.5 : DIAMOND_WIDTH * 0.5
    let diamondStyles = {
        left: `-${diamondPosition}%`,
        width: `${DIAMOND_WIDTH}%`,
        height: data.comparativeBar ? BAR_HEIGHT * 0.5 : BAR_HEIGHT,
        backgroundColor,
    }
    //Upper error bar styles
    const upperErrorBarWidth = errorBars ? Math.abs(transformToPercent(data.upperValue, scale, mode) - barWidth) : null
    let upperErrorBarStyles = errorBars ? { height: '2px', border: 'none', backgroundColor, width: `${upperErrorBarWidth}%`, left: `-${diamondPosition}%` } : null
    //Label styles
    let xLabelPos = 0
    let yLabelPos = 0
    if (errorBars) {
        xLabelPos = upperErrorBarWidth === lowerErrorBarWidth ? Math.abs(upperErrorBarWidth - 0.5) : lowerErrorBarWidth
    }
    //HACK to avoid label to cutoff for hc3 and ed1
    if ((data.parentCategory === 'White' && data.childCategory === 'Share of business executives') || (data.parentCategory === 'White' && data.childCategory === 'Ownership of businesses')) {
        xLabelPos = 25
        yLabelPos = BAR_HEIGHT + 5
    }

    let labelStyles = { color: COLOR_MAP_VIZ[data.parentCategory], fontWeight: isChildren(data) ? 'normal' : 'bold', left: `-${xLabelPos}%`, top: `-${yLabelPos}px` }

    //Position is calculated based on the referencePoint position
    const difference = mode !== BarchartModes.Regular ? (scale(Math.min(referencePoint, data.diffValue)) * 100) / scale.range()[1] : null

    if (difference !== null) {
        backgroundColor = difference > 0 ? ComparisonColors.Positive : ComparisonColors.Negative
        backgroundColor = data.referencePoint ? ComparisonColors.Selected : backgroundColor
        const percentReferencePoint = transformToPercent(referencePoint, scale, mode)

        //New bar styles
        const barPosition = difference >= 0 ? percentReferencePoint : percentReferencePoint - barWidth
        barStyles = { ...barStyles, backgroundColor, left: `${barPosition}%`, opacity: OPACITY.childrenOpacity }

        //Updated lower error bar styles
        const lowerErrorBarWidth = Math.abs(transformToPercent(data.value, scale, mode) - transformToPercent(data.lowerValue, scale, mode))
        const positionLowerErrorBar = difference >= 0 ? percentReferencePoint - lowerErrorBarWidth : barPosition - barWidth - lowerErrorBarWidth
        lowerErrorBarStyles =
            errorBars && !data.comparativeBar ? { width: `${lowerErrorBarWidth}%`, left: `${positionLowerErrorBar - DIAMOND_WIDTH * 0.5}%`, height: '2px', border: 'none', backgroundColor } : null

        //Updated diamond styles
        let positionDiamond = 0
        if (errorBars) {
            positionDiamond = positionLowerErrorBar - DIAMOND_WIDTH * 0.5
        } else if (!errorBars) {
            if (difference >= 0) {
                positionDiamond = barPosition - DIAMOND_WIDTH * 0.5
            } else {
                positionDiamond = barPosition - barWidth - DIAMOND_WIDTH * 0.5
            }
        }

        diamondStyles = { left: `${positionDiamond}%`, width: `${DIAMOND_WIDTH}%`, height: data.comparativeBar ? BAR_HEIGHT * 0.5 : BAR_HEIGHT, backgroundColor }

        //Updated upper error bar styles
        const upperErrorBarWidth = Math.abs(transformToPercent(data.upperValue, scale, mode) - transformToPercent(data.value, scale, mode))
        upperErrorBarStyles = errorBars ? { width: `${upperErrorBarWidth}%`, left: `${positionDiamond}%`, height: '2px', border: 'none', backgroundColor } : null

        //Updated label styles
        let positionLabel = 0
        if (errorBars) {
            if (difference >= 0) {
                positionLabel = positionLowerErrorBar
            } else {
                const errorBarDiff = upperErrorBarWidth > barWidth ? 0 : barWidth - upperErrorBarWidth
                if (errorBarDiff !== 0) {
                    positionLabel = barPosition - Math.abs(lowerErrorBarWidth) - Math.abs(upperErrorBarWidth)
                } else {
                    positionLabel = positionDiamond
                }
            }
        } else {
            positionLabel = barPosition - DIAMOND_WIDTH * 0.5
        }

        labelStyles = { left: `${positionLabel + 1}%`, color: backgroundColor, fontWeight: data.referencePoint ? 'bold' : 'normal' }
    }
    let barClasses = classNames('bar-wrapper', { 'is-parent': !isChildren(data) })
    if (data.comparativeBar) {
        barClasses = classNames('bar-wrapper', { 'is-comparative-bar': data.comparativeBar })
    }

    const barTooltip = ({ tooltip, category, selected, parent, value, unit, decimals, additionalData }) => {
        let formattedTooltip = tooltip
        if (tooltip.includes('{LABEL OF FOCUSED GROUP}')) {
            formattedTooltip = formattedTooltip.replace('{LABEL OF FOCUSED GROUP}', selected)
        }
        if (tooltip.includes('{LABEL}') && !tooltip.includes('{VALUE1}')) {
            formattedTooltip = formattedTooltip.replace('{LABEL}', category)
        }

        if (tooltip.includes('{VALUE}') || tooltip.includes('{VALUE:SHARE}') || tooltip.includes('{VALUE1}')) {
            //To handle the language for the re-focused tooltip
            if (tooltip.includes('percentage points')) {
                formattedTooltip = formattedTooltip.replace('{VALUE}', unitFormatter(Math.abs(value), unit, decimals).replace('%', ''))
                formattedTooltip = formattedTooltip.replace('{VALUE:SHARE}', unitFormatter(Math.abs(value), unit, decimals).replace('%', ''))
            } else {
                formattedTooltip = formattedTooltip.replace('{VALUE}', unitFormatter(Math.abs(value), unit, decimals))
                formattedTooltip = formattedTooltip.replace('{VALUE:SHARE}', unitFormatter(Math.abs(value), unit, decimals))
            }

            formattedTooltip = formattedTooltip.replace('{VALUE1}', unitFormatter(Math.abs(value), unit, decimals).replace('%', ''))
            formattedTooltip = formattedTooltip.replaceAll('{LABEL}', parent)
        }
        if (additionalData && tooltip.includes('{VALUE:NUM}')) {
            formattedTooltip = formattedTooltip.replace('{VALUE:NUM}', unitFormatter(additionalData, '', decimals))
        }

        if (additionalData && tooltip.includes('{VALUE2}')) {
            formattedTooltip = formattedTooltip.replace('{VALUE2}', unitFormatter(additionalData, unit, decimals).replace('%', ''))
        }

        //Re-focused tooltip
        if (value > 0) {
            if (tooltip.includes('higher/lower')) {
                formattedTooltip = formattedTooltip.replace('higher/lower', 'higher')
            }
            if (tooltip.includes('more/less')) {
                formattedTooltip = formattedTooltip.replace('more/less', 'more')
            }
            if (tooltip.includes('more/fewer')) {
                formattedTooltip = formattedTooltip.replace('more/fewer', 'more')
            }
        } else if (value < 0) {
            if (tooltip.includes('higher/lower')) {
                formattedTooltip = formattedTooltip.replace('higher/lower', 'lower')
            }
            if (tooltip.includes('more/less')) {
                formattedTooltip = formattedTooltip.replace('more/less', 'less')
            }
            if (tooltip.includes('more/fewer')) {
                formattedTooltip = formattedTooltip.replace('more/fewer', 'fewer')
            }
        } else if (value === 0) {
            formattedTooltip = category
        }

        return `<div>
             <div>${formattedTooltip} </div>
          </div>`
    }

    const tooltipContent =
        mode === BarchartModes.Regular
            ? barTooltip({
                  tooltip,
                  category: data.childCategory,
                  selected: barSelected,
                  parent: data.parentCategory,
                  value: data.value,
                  unit,
                  decimals,
                  additionalData: data.additionalData,
              })
            : barTooltip({
                  tooltip,
                  category: data.childCategory,
                  selected: barSelected,
                  parent: data.parentCategory,
                  value: data.diffValue,
                  unit,
                  decimals,
                  additionalData: data.additionalData,
              })

    return (
        <div className={barClasses} style={{ marginLeft: `${config.left}px`, marginRight: `${config.right}px` }}>
            <div className="bar" style={barStyles} onClick={canClick ? () => barSelection(data.childCategory) : undefined} data-tip={!data.comparativeBar ? tooltipContent : null} />
            {data.additionalValue && <div className="bar" style={additionalBarStyles} />}
            {errorBars && <hr style={lowerErrorBarStyles} data-tip={!data.comparativeBar ? tooltipContent : null} />}
            <div className="diamond-shape" style={diamondStyles} onClick={() => barSelection(data.childCategory)} data-tip={!data.comparativeBar ? tooltipContent : null} />
            {errorBars && <hr style={upperErrorBarStyles} data-tip={!data.comparativeBar ? tooltipContent : null} />}
            <div className="labels" style={labelStyles} onClick={() => barSelection(data.childCategory)} data-tip={!data.comparativeBar ? tooltipContent : null}>
                {!!(difference && difference !== 0) && <span className="highlighted">{addSign(data.diffValue, unit, mode, decimals)}</span>}
                <span>{data.childCategory}</span>
            </div>
            <ReactTooltip html className="viz-tooltip" backgroundColor="#FFFFFF" multiline={true} />
        </div>
    )
}

export default Bar

const addSign = (value, unit, mode, decimals) => {
    const formattedValue = unitFormatter(Math.abs(value), unit, decimals)
    if (value > 0 && mode === BarchartModes.Focused) {
        return `+${formattedValue}`
    } else if (value < 0 && mode === BarchartModes.Focused) {
        return `-${formattedValue}`
    } else return formattedValue
}
const transformToPercent = (value, scale, mode) => {
    if (mode === BarchartModes.Regular) {
        return (scale(value) * 100) / scale.range()[1]
    } else {
        return (Math.abs(scale(value) - scale(0)) * 100) / scale.range()[1]
    }
}
