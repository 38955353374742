import React, { useRef, useState, useEffect } from 'react'
import _ from 'lodash'
import { scaleLinear } from 'd3-scale'
import { extent, group, least } from 'd3-array'
import { line, area } from 'd3-shape'
import { pointer } from 'd3-selection'
import useMediaQuery from '@mui/material/useMediaQuery'

import Axis from '../../common/svg/axis'
import Line from '../../common/svg/line'
import { getAxisFormatters, unitFormatter } from '../../../utils//format'
import { computeDataDomain } from '../../../utils/axis'
import { COLOR_MAP_VIZ, ColorLines, ComparisonColors } from '../../../utils/theme'

import './line-chart.scss'
import { CulturalCommunities, Breakpoint } from '../../../utils/enum'

const OPACITY = {
    childrenOpacity: 0.3,
    parentOpacity: 1,
    backgroundOpacity: 0.15,
}

const CIRCLE_RADIUS = 5

const LineChart = ({ data, unit = 'percent', domain, errorBars, lineSelected, decimals, defaultLineSelected, showZeroLine }) => {
    const lineChartRef = useRef(null)
    const isTabletWide = useMediaQuery(`(max-width:${Breakpoint.TabletWide}px)`)
    const isMobile = useMediaQuery(`(max-width:${Breakpoint.Mobile}px)`)

    const config = {
        top: 35,
        right: 180,
        bottom: 50,
        left: 60,
    }

    if (isTabletWide && !isMobile) {
        config.left = config.left * 0.75
        config.right = config.right * 0.75
    } else if (isMobile) {
        config.left = config.left * 0.75
        config.right = config.right * 0.25
    }

    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [isClient, setClient] = useState(false) //SSR workaround to add brushing
    const [selected, setSelected] = useState('')

    useEffect(() => {
        if (lineChartRef.current && data.length > 0) {
            setWidth(lineChartRef.current.clientWidth)
            setHeight(lineChartRef.current.clientHeight)
            setClient(true)
            if (lineSelected) {
                //To avoid the export layout to always show default line
                setSelected(lineSelected)
            } else setSelected(defaultLineSelected)
        }
    }, [data, height, lineChartRef])

    useEffect(() => {
        setSelected(lineSelected)
    }, [lineSelected])

    const chartWidth = width - config.right - config.left
    const chartHeight = height - config.top - config.bottom

    //Scales
    const xDomain = extent(data, (d) => d.date)
    const yDomain = domain.length > 0 ? domain : computeDataDomain(data, unit)
    const xTickValues = _.uniq(data.map((d) => d.date)) //Just display dates for available data
    const xAxisScale = scaleLinear().domain(xDomain).range([0, chartWidth])
    const yAxisScale = scaleLinear().domain(yDomain).range([chartHeight, 0]).nice()
    const yTickValues = yAxisScale.ticks()

    //Group data by cultural community
    const groups = Array.from(group(data, (d) => `${d.childCategory}/${d.parentCategory}`))
    const lineFn = line()
        .x((d) => xAxisScale(d.date))
        .y((d) => yAxisScale(d.value))
    const areaFn = area()
        .x((d) => xAxisScale(d.date))
        .y0((d) => yAxisScale(d.upperValue))
        .y1((d) => yAxisScale(d.lowerValue))

    const lines = groups.map((group) => {
        return { key: group[0], line: lineFn(group[1]), area: areaFn(group[1]), points: group[1] }
    })
    const points = _.flatten(lines.map((line) => line.points))

    //https://observablehq.com/@d3/multi-line-chart
    const handleOverlayMouseMove = (event) => {
        event.preventDefault()
        const point = pointer(event, this)
        const closestLine = least(points, (d) => Math.hypot(xAxisScale(d.date) - (point[0] - config.left), yAxisScale(Math.abs(d.value)) - (point[1] - config.top)))
        setSelected(closestLine.childCategory)
    }

    const resetSelection = () => {
        if (!lineSelected) {
            setSelected(defaultLineSelected)
        } else {
            setSelected(lineSelected)
        }
    }

    const hasDimensions = chartWidth > 0 && chartHeight > 0
    const showMigrationLabels = data[0]?.indicator === 'sb3_net_rate' ? true : false

    return (
        <div className="line-chart" ref={lineChartRef}>
            {isClient && hasDimensions && (
                <svg viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg" onMouseMove={(e) => handleOverlayMouseMove(e)} onMouseLeave={resetSelection}>
                    <g transform={`translate(${config.left},${config.top})`}>
                        <Axis scale={xAxisScale} position="bottom" offset={[0, chartHeight]} tickFormat={getAxisFormatters()} tickValues={xTickValues} />
                        <Axis scale={yAxisScale} position="left" offset={[-10, 0]} tickValues={yTickValues} tickFormat={getAxisFormatters(unit)} />
                        <g className="line-chart-y-axis-lines">
                            {yTickValues.map((value) => {
                                const strokeColor = showZeroLine && value === 0 ? ColorLines.DarkGray : ColorLines.LightGray
                                const lineData = [
                                    [0, yAxisScale(value)],
                                    [chartWidth, yAxisScale(value)],
                                ]
                                return <Line key={`axis-line-${value}`} data={lineData} styles={{ strokeWidth: 1, opacity: 1, stroke: strokeColor }} />
                            })}
                        </g>
                        <g className="line-chart-lines">
                            {lines.map((l) => {
                                const childCategory = l.key.split('/')[0]
                                const parentCategory = l.key.split('/')[1]
                                const color = COLOR_MAP_VIZ[parentCategory]
                                const strokeWidth = selected && selected === childCategory ? 3 : 2
                                let opacity = OPACITY.parentOpacity
                                const showFullOpacity = (selected && selected === childCategory) || lines.length <= 3
                                if (showFullOpacity) {
                                    opacity = OPACITY.parentOpacity
                                } else if (selected && selected !== childCategory) {
                                    opacity = OPACITY.backgroundOpacity
                                }
                                const lineStyles = { fill: 'none', stroke: color, strokeWidth, opacity }
                                const areaStyles = { fill: color, stroke: 'none', opacity: 0.3 }

                                let labels = [childCategory]
                                if (childCategory === CulturalCommunities.BlackAncestry) {
                                    labels = childCategory.split(',')
                                } else if (childCategory === CulturalCommunities.MajorityPOC) {
                                    labels = ['Majority-people of', 'color neighborhoods']
                                } else if (childCategory === CulturalCommunities.MajorityWhite) {
                                    labels = ['Majority-white', 'neighborhoods']
                                }

                                return (
                                    <g key={`line-${l.key}`}>
                                        {errorBars && selected && selected === childCategory && <path d={l.area} style={areaStyles} />}
                                        <path d={l.line} style={lineStyles} />

                                        {showFullOpacity &&
                                            labels.map((label, idx) => {
                                                return (
                                                    <text key={idx} x={xAxisScale(l.points[0].date) + 10} y={yAxisScale(l.points[0].value) + 20 * idx} dy={'.35em'} style={{ fill: color }}>
                                                        {label}
                                                    </text>
                                                )
                                            })}

                                        {showFullOpacity &&
                                            l.points.map((point, idx) => {
                                                const paddingFirstPoint = idx === l.points.length - 1 ? 30 : 0 //First date overlaps with y axis a bit
                                                return (
                                                    <React.Fragment key={`annotations-${idx}`}>
                                                        <circle cx={xAxisScale(point.date)} cy={yAxisScale(point.value)} r={CIRCLE_RADIUS} style={{ fill: color }}></circle>
                                                        <text x={xAxisScale(point.date) + paddingFirstPoint} y={yAxisScale(point.value)} dx="-2em" dy="-.65em" style={{ fill: color }}>
                                                            {unitFormatter(point.value, unit, decimals)}
                                                        </text>
                                                    </React.Fragment>
                                                )
                                            })}
                                    </g>
                                )
                            })}
                        </g>
                        {showMigrationLabels && (
                            <g className="additional-labels">
                                <text x={0} y={0} dy={'.35em'} style={{ fill: ColorLines.DarkGray }}>
                                    Population gain
                                </text>
                                <text x={0} y={chartHeight} dy={'.35em'} style={{ fill: ColorLines.DarkGray }}>
                                    Population loss
                                </text>
                            </g>
                        )}
                    </g>
                </svg>
            )}
        </div>
    )
}

export default LineChart
