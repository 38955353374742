import React, { useEffect } from 'react'
import _ from 'lodash'
import useMediaQuery from '@mui/material/useMediaQuery'
import { extent, group } from 'd3-array'
import { scaleLinear } from 'd3-scale'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'

import { useChartDimensions } from '../../../utils/dimensions'
import { ComparisonColors, ColorLines } from '../../../utils/theme'
import { getAxisFormatters, unitFormatter } from '../../../utils//format'
import { Breakpoint } from '../../../utils/enum'

import Axis from '../../common/svg/axis'
import Line from '../../common/svg/line'

import './diverging-bar-chart.scss'

const MARGIN = {
    top: 20,
    right: 85,
    bottom: 10,
    left: 20,
}
const BAR_HEIGHT = 16
const DIAMOND_WIDTH = 0.5 // Percentage

const POPULATION_GAIN = 'Population Gain ->'
const POPULATION_LOSS = '<- Population Loss'

const LABEL_Y_POS = 35

const DivergingBarChart = ({ data, unit, decimals }) => {
    const [barChartRef, dimensions] = useChartDimensions(MARGIN)
    const isTabletWide = useMediaQuery(`(max-width:${Breakpoint.TabletWide}px)`, { noSsr: true })

    useEffect(() => {
        ReactTooltip.rebuild()
    })

    const chartWidth = dimensions.width
    const height = dimensions.height
    //Group by cultural community
    const groups = Array.from(group(data, (d) => `${d.childCategory}/${d.parentCategory}`))
    //Compute the x extent
    const values = groups.map((d) => {
        const positiveValues = d[1].filter((d) => d.value > 0)
        const negativeValues = d[1].filter((d) => d.value < 0)
        const positiveSum = positiveValues.reduce((acc, dd, idx) => {
            const confidenceInterval = idx === positiveValues.length - 1 ? dd.upperValue : 0
            return acc + dd.value + confidenceInterval //Only add the confidence interval for the international in-migration (sb3_inflow_int_rate)
        }, 0)
        const negativeSum = negativeValues.reduce((acc, dd) => {
            return acc + Math.abs(dd.value) + Math.abs(dd.lowerValue) //Positive values
        }, 0)
        return [-negativeSum, positiveSum]
    })

    const xAxisScaleDomain = extent(values.flat().map((d) => Math.round(d)))
    const xAxisScale = scaleLinear().domain(xAxisScaleDomain).range([MARGIN.left, chartWidth]).nice()
    let tickValues = xAxisScale.ticks()

    const labelXPosResponsive = !isTabletWide ? xAxisScale(tickValues[tickValues.length - 2]) : xAxisScale(tickValues[tickValues.length - 4])

    const barsTooltip = (groups, unit, decimals) => {
        const tooltips = groups.map((group, idx) => {
            //Label
            let formattedTooltip = group.tooltip
            formattedTooltip = formattedTooltip?.replace('{LABEL}', group.childCategory)
            //Value
            const color = group.value > 0 ? ComparisonColors.Positive : ComparisonColors.Negative
            const opacity = idx !== 2 ? 1 : 0.6
            const formattedValue = unitFormatter(Math.abs(group.value), unit, decimals)

            formattedTooltip = formattedTooltip?.replace('{VALUE}', formattedValue, decimals)

            if (group.value > 0 && formattedTooltip.includes('TO/FROM')) {
                formattedTooltip = formattedTooltip?.replace('TO/FROM', 'to')
            } else {
                formattedTooltip = formattedTooltip?.replace('TO/FROM', 'from')
            }

            return `<div style="color:${color}; opacity:${opacity};" class="tooltip-content">
            ${formattedTooltip}
            </div>`
        })

        const tooltipContainer = tooltips.join('')
        const additionalLabel = groups.find((d) => d.indicator !== 'sb3_net_rate') ? 'In the past year:' : ''

        return `<div class="diverging-tooltip-label">
        ${additionalLabel}
        <div class="tooltip-container">
       ${tooltipContainer} 
     </div></div>`
    }
    return (
        <>
            <div className="bar-chart">
                <div className="bar-chart-axis-container">
                    <svg className="diverging-bar-chart-axis">
                        <Axis scale={xAxisScale} position="top" offset={[0, MARGIN.top]} tickValues={tickValues} tickFormat={getAxisFormatters('')} />
                        <text className="axis-labels" x={labelXPosResponsive} y={LABEL_Y_POS} fill={ComparisonColors.Positive}>
                            {POPULATION_GAIN}
                        </text>
                        <text className="axis-labels" x={xAxisScale(tickValues[0])} y={LABEL_Y_POS} fill={ComparisonColors.Negative}>
                            {POPULATION_LOSS}
                        </text>
                    </svg>
                    <svg className="bar-chart-axis-lines">
                        {tickValues.map((value) => {
                            const stroke = value === 0 ? ColorLines.DarkGray : ColorLines.LightGray
                            const lineData = [
                                [xAxisScale(value), 0],
                                [xAxisScale(value), height],
                            ]
                            return <Line key={`line-${value}`} data={lineData} styles={{ strokeWidth: 1, opacity: 1, stroke: stroke }} />
                        })}
                    </svg>
                </div>
                <div className="bar-chart-bars" ref={barChartRef} style={{ top: `${MARGIN.top + 10}px`, marginRight: `${MARGIN.right}px` }}>
                    {groups.map((group) => {
                        const childCategory = group[0].split('/')[0]
                        const fontWeight = isChildren(group[0]) ? 'normal' : 'bold'
                        const barClasses = classNames('bar-wrapper', { 'is-parent': !isChildren(group[0]) })
                        const percentReferencePoint = (xAxisScale(0) * 100) / xAxisScale.range()[1]
                        const tooltipContent = barsTooltip(_.cloneDeep(group[1]), unit, decimals)
                        const numIndicators = group[1].length
                        const numPositiveIndicators = group[1].filter((d) => d.value >= 0).length

                        return (
                            //Negative values
                            <div className={barClasses} key={`bars-${group}`}>
                                {group[1]
                                    .filter((d) => d.value < 0)
                                    .map((d, idx) => {
                                        const color = ComparisonColors.Negative
                                        const barWidth = transformToPercent(d.value, xAxisScale)
                                        const barPosition = percentReferencePoint - barWidth
                                        const lowerErrorBarWidth = barWidth - transformToPercent(d.lowerValue, xAxisScale)
                                        const upperErrorBarWidth = transformToPercent(d.upperValue, xAxisScale) + barWidth
                                        const lowerErrorBarPosition = numIndicators > 1 ? barPosition - barWidth - lowerErrorBarWidth : barPosition - barWidth + lowerErrorBarWidth

                                        const barStyles = {
                                            width: `${barWidth}%`,
                                            backgroundColor: color,
                                            height: BAR_HEIGHT,
                                            left: `${barPosition}%`,
                                            opacity: 0.6,
                                            borderLeft: idx === 1 ? '1px solid white' : '',
                                        }
                                        const lowerErrorBarStyles = {
                                            height: '2px',
                                            border: 'none',
                                            backgroundColor: color,
                                            width: `${Math.abs(lowerErrorBarWidth)}%`,
                                            left: `${lowerErrorBarPosition}%`,
                                        }
                                        const diamondStyles = {
                                            width: `${DIAMOND_WIDTH}%`,
                                            height: BAR_HEIGHT,
                                            left: `${barPosition - DIAMOND_WIDTH * 0.5}%`,
                                            backgroundColor: color,
                                        }

                                        const upperErrorBarStyles = {
                                            height: '2px',
                                            border: 'none',
                                            backgroundColor: color,
                                            width: `${Math.abs(upperErrorBarWidth)}%`,
                                            left: `${lowerErrorBarPosition}%`,
                                        }

                                        const labelStyles = {
                                            color: ComparisonColors.Negative,
                                            fontWeight: fontWeight,
                                            left: `${lowerErrorBarPosition + 1}%`,
                                        }

                                        return (
                                            <React.Fragment key={`bar-${idx}`}>
                                                <div className="bar" style={barStyles} data-tip={tooltipContent} />
                                                <hr style={lowerErrorBarStyles} data-tip={tooltipContent} />
                                                <div className="diverging-diamond-shape" style={diamondStyles} data-tip={tooltipContent} />
                                                {numIndicators < 2 && <hr style={upperErrorBarStyles} data-tip={tooltipContent} />}
                                                {numIndicators < 2 && (
                                                    <div key={`labels-${group}`} className="labels" style={labelStyles} data-tip={tooltipContent}>
                                                        {childCategory}
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )
                                    })}
                                {group[1]
                                    .filter((d) => d.value >= 0)
                                    .map((d, idx) => {
                                        const color = ComparisonColors.Positive
                                        const barWidth = transformToPercent(d.value, xAxisScale)
                                        const negativeBarWidth = transformToPercent(group[1][0].value, xAxisScale)
                                        let lowerErrorBarWidth = negativeBarWidth - transformToPercent(group[1][0].lowerValue, xAxisScale)
                                        if (numIndicators === 1) {
                                            if (group[1][0].lowerValue < 0) {
                                                lowerErrorBarWidth = negativeBarWidth + transformToPercent(group[1][0].lowerValue, xAxisScale)
                                            }
                                        }

                                        const barPosition = numIndicators > 1 ? percentReferencePoint - negativeBarWidth - lowerErrorBarWidth : percentReferencePoint
                                        const upperErrorBarWidth = transformToPercent(d.upperValue, xAxisScale) - barWidth
                                        const upperErrorBarPosition = numIndicators > 1 ? barPosition : barPosition - Math.abs(lowerErrorBarWidth)
                                        const lowerErrorBarPosition = barPosition - Math.abs(lowerErrorBarWidth)
                                        const barStyles = {
                                            width: `${barWidth}%`,
                                            backgroundColor: color,
                                            height: BAR_HEIGHT,
                                            left: `${barPosition}%`,
                                            opacity: idx === 0 && numIndicators > 1 ? 1 : 0.6,
                                            borderLeft: idx === 1 ? '1px solid white' : '',
                                        }
                                        const upperErrorBarStyles = {
                                            height: '2px',
                                            border: 'none',
                                            backgroundColor: color,
                                            width: `${upperErrorBarWidth}%`,
                                            left: `${upperErrorBarPosition}%`,
                                        }
                                        const lowerErrorBarStyles = {
                                            height: '2px',
                                            border: 'none',
                                            backgroundColor: color,
                                            width: `${lowerErrorBarWidth}%`,
                                            left: `${lowerErrorBarPosition}%`,
                                        }

                                        const accBarWidth = group[1]
                                            .filter((d) => d.value >= 0)
                                            .reduce((acc, dd) => {
                                                return acc + transformToPercent(dd.value, xAxisScale)
                                            }, 0)

                                        const diamondStyles = {
                                            width: `${DIAMOND_WIDTH}%`,
                                            height: BAR_HEIGHT,
                                            left: `${percentReferencePoint + accBarWidth - DIAMOND_WIDTH * 0.5}%`,
                                            backgroundColor: color,
                                        }

                                        const labelPosition = numIndicators > 1 ? barPosition + 1 : barPosition - Math.abs(lowerErrorBarWidth) + 1
                                        const labelStyles = { color: ComparisonColors.Positive, fontWeight: fontWeight, left: `${labelPosition}%` }

                                        return (
                                            <React.Fragment key={`bar-${idx}`}>
                                                <div className="bar" style={barStyles} data-tip={tooltipContent} />
                                                {idx === numPositiveIndicators - 1 && (
                                                    <>
                                                        {numIndicators < 2 && <hr style={lowerErrorBarStyles} data-tip={tooltipContent} />}
                                                        <div className="diverging-diamond-shape" style={diamondStyles} data-tip={tooltipContent} />
                                                        <hr style={upperErrorBarStyles} data-tip={tooltipContent} />
                                                        <div key={`labels-${group}`} className="labels" style={labelStyles} data-tip={tooltipContent}>
                                                            {childCategory}
                                                        </div>
                                                    </>
                                                )}
                                            </React.Fragment>
                                        )
                                    })}
                                <ReactTooltip html className="viz-tooltip" backgroundColor="#FFFFFF" multiline={true} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default DivergingBarChart

const transformToPercent = (value, scale) => {
    return (Math.abs(scale(value) - scale(0)) * 100) / scale.range()[1]
}

const isChildren = (data) => {
    const splitted = data.split('/')
    return splitted[0] !== splitted[1]
}
