import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { ColorViz, COLOR_MAP_TOPICS } from '../../utils/theme'

import './accordion.scss'

const SimpleAccordion = ({ list, category }) => {
    const [expanded, setExpanded] = useState(0) //Have first panel expande by default

    const accordionStyle = {
        '&.Mui-expanded': {
            margin: '0',
        },
    }

    const accordionHeaderStyle = [
        { backgroundColor: COLOR_MAP_TOPICS[category], opacity: 0.7, color: '#FFFFFF', textTransform: 'uppercase' },
        {
            '&.Mui-expanded': {
                backgroundColor: COLOR_MAP_TOPICS[category],
                opacity: 1,
            },
        },
    ]

    const handleChange = (id) => (_, isExpanded) => {
        setExpanded(isExpanded ? id : false)
    }

    return (
        <div>
            {list.map((item, idx) => {
                return (
                    <React.Fragment key={`side-bar-accordion-${idx}`}>
                        {item.title && item.content && (
                            <Accordion sx={{ borderBottom: '1px solid #FFFFFF' }} expanded={expanded === idx} onChange={handleChange(idx)} sx={accordionStyle}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#FFFFFF' }} />} className="accordion-summary" sx={accordionHeaderStyle}>
                                    <Typography>{item.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography component={'span'} variant={'body2'}>
                                        <div dangerouslySetInnerHTML={{ __html: item.content.replace(/href/g, "target='_blank' href") }} />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </React.Fragment>
                )
            })}
        </div>
    )
}

export default SimpleAccordion
