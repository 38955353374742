import * as React from 'react'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const SimpleCheckbox = ({ label, checked, onChange, styles }) => {
    return (
        <FormGroup>
            <FormControlLabel control={<Checkbox size="small" checked={checked} onChange={onChange} sx={styles} />} label={label} />
        </FormGroup>
    )
}

export default SimpleCheckbox
