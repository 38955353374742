import React, { useContext, useEffect, useRef } from 'react'
import html2canvas from 'html2canvas'
import _ from 'lodash'

import { IndicatorContext } from '../indicator-context'

import BarChart from '../comparison/bar-chart'
import LineChart from '../time/line-chart'
import DivergingBarChart from '../comparison/diverging-bar-chart'
import CEILogo from '../../../images/cei-logo.png'

import { ToggleOptions, BarchartModes, MigrationToggleOptions } from '../../../utils/enum'
import { computeDataDomain } from '../../../utils/axis'

import './export-layout.scss'

export const ExportLayout = () => {
    const {
        downloadImage,
        setDownloadImage,
        indicatorMetadata,
        view,
        barchartMode,
        barchartData,
        diffBarchartData,
        lineChartData,
        lineSelected,
        defaultLineSelected,
        stackedBarchartData,
        focusColorCommCheckboxStatus,
    } = useContext(IndicatorContext)
    const exportRef = useRef()

    useEffect(() => {
        setTimeout(() => {
            html2canvas(exportRef.current, { scale: 2, logging: true }).then((canvas) => {
                const a = document.createElement('a')
                a.href = canvas.toDataURL('image/png').replace('image/jpeg', 'image/octet-stream')
                a.download = `indicator-${indicatorMetadata.Indicator_code}.png`
                a.click()
                setDownloadImage(false)
            })
        }, 500)
    }, [])

    let subtitle = view === ToggleOptions.Comparison ? `${indicatorMetadata.Geography}, ${indicatorMetadata.Year___Point_in_time}` : indicatorMetadata.Geography

    let barChartDataAvailable = barchartData
    if (barchartMode !== BarchartModes.Regular && diffBarchartData.length) {
        barChartDataAvailable = diffBarchartData
    }
    const marginErrorAvailable = indicatorMetadata.Margin_of_error === 'TRUE' ? true : false
    let barChartDomain = []
    if (indicatorMetadata.Indicator_code === 'ig4' || focusColorCommCheckboxStatus || indicatorMetadata.Indicator_code !== 'sb3') {
        const domain = computeDataDomain(barChartDataAvailable)
        barChartDomain = domain
    }

    let lineChartDomain = []
    //Remove this once the migration rate gets clarified
    if (lineChartData.length) {
        const marginError = indicatorMetadata.Indicator_code !== 'sb3' ? marginErrorAvailable : false //Hack: net count within the migration rate does not have CI
        let min = marginError ? _.minBy(lineChartData, 'lowerValue')?.lowerValue : _.minBy(lineChartData, 'value')?.value
        let max = marginError ? _.maxBy(lineChartData, 'upperValue')?.upperValue : _.maxBy(lineChartData, 'value')?.value
        if (indicatorMetadata.Indicator_code === 'ao4') {
            min = 0
        }
        lineChartDomain = [Math.floor(min), Math.ceil(max)]
    }

    return (
        <div className="export-layout" ref={exportRef}>
            <div className="export-heading">
                {/* <h2 className="title">Indicators for an Inclusive Regional Economy</h2> */}
                <div className="viz-title">{indicatorMetadata.Indicator_long_name}</div>
                <div className="viz-subtitle">{subtitle}</div>
            </div>

            <div className="export-content">
                {downloadImage && (
                    <div className="inner-viz-container">
                        {view === ToggleOptions.Comparison && (
                            <>
                                <BarChart
                                    mode={barchartMode}
                                    data={barChartDataAvailable}
                                    unit={indicatorMetadata.Unit_of_measurement}
                                    errorBars={marginErrorAvailable}
                                    canClick={true}
                                    barSelection={null}
                                    domain={barChartDomain}
                                    tooltip={''}
                                    decimals={indicatorMetadata.Decimals}
                                />
                            </>
                        )}
                        {(view === MigrationToggleOptions.InOut || view === MigrationToggleOptions.Net) && indicatorMetadata.Indicator_code === 'sb3' && (
                            <>
                                <DivergingBarChart data={stackedBarchartData} />
                            </>
                        )}
                        {view === ToggleOptions.Time && (
                            <>
                                <LineChart
                                    data={lineChartData}
                                    unit={indicatorMetadata.Unit_of_measurement}
                                    domain={lineChartDomain}
                                    lineSelected={lineSelected}
                                    defaultLineSelected={defaultLineSelected}
                                    lineSelection={null}
                                    errorBars={marginErrorAvailable}
                                    decimals={indicatorMetadata.Decimals}
                                />
                            </>
                        )}
                    </div>
                )}
            </div>
            <div className="export-footer">
                <div className="viz-misc">
                    <div className="source-label">Source: {indicatorMetadata.Data_source}</div>
                    <img src={CEILogo} alt="CEI logo" />
                </div>
            </div>
        </div>
    )
}

export default ExportLayout
