import React, { useState, useContext, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import useMediaQuery from '@mui/material/useMediaQuery'

import { IndicatorContext } from './indicator-context'

import ToggleButtons from '../controls/toggle-buttons'
import BasicSelect from '../controls/basic-select'
import SimpleButton from '../controls/simple-button'
import SimpleCheckbox from '../controls/simple-checkbox'
import BarChart from './comparison/bar-chart'
import DivergingBarChart from './comparison/diverging-bar-chart'
import ModalDialog from '../common/modal-dialog'
import ChartInstructions from './chart-instructions'
import ExportLayout from './export/export-layout'

import LineChart from './time/line-chart'

import { ButtonTypes, ToggleOptions, BarchartModes, ConfidenceIntervalMessage, Topics, MigrationToggleOptions, Breakpoint } from '../../utils/enum'
import { TOGGLE_BUTTONS, TOGGLE_BUTTONS_MIGRATION } from '../../utils/const'
import { ButtonsColors, ColorViz } from '../../utils/theme'
import { computeDataDomain } from '../../utils/axis'

import './viz-container.scss'

const RESET_SELECT_LABEL = 'Show all communities'

const VizContainer = ({ metadata }) => {
    const {
        Indicator_code,
        Indicator_long_name,
        Topic_area,
        Geography,
        Unit_of_measurement,
        Time_series,
        Margin_of_error,
        Data_source,
        Data_Source___link,
        Comparison_against_population,
        Primary_tooltip,
        Re_focused_tooltip,
        NEW_re_focused_tooltip,
        Comparison_tooltip,
        Indicator_notes,
        Year___Point_in_time,
        Decimals,
    } = metadata
    const {
        view,
        setView,
        setIndicator,
        setIndicatorMetadata,
        barchartData,
        barchartMode,
        setBarchartMode,
        barSelected,
        setBarSelected,
        diffBarchartData,
        lineChartData,
        lineSelected,
        defaultLineSelected,
        setLineSelected,
        certificateAchieversCheckboxStatus,
        setCertificateAchieversCheckboxStatus,
        stackedBarchartData,
        focusColorCommCheckboxStatus,
        setFocusColorCommCheckboxStatus,
        downloadImage,
        setDownloadImage,
    } = useContext(IndicatorContext)

    const [openModal, setOpenModal] = React.useState(false)
    const [showIndicatorNotes, setShowIndicatorNotes] = useState(false)
    const [copied, setCopied] = useState(false)
    const [url, setUrl] = useState('')
    const isTabletWide = useMediaQuery(`(max-width:${Breakpoint.TabletWide}px)`)
    const [scrollValue, setScrollValue] = useState(0)

    const [isClient, setClient] = useState(false)
    useEffect(() => {
        setClient(true)
        setUrl(window.location.href)
    }, [])

    useEffect(() => {
        setIndicator(metadata.Indicator_code)
        setIndicatorMetadata(metadata)
    }, [metadata])

    //Update URL
    useEffect(() => {
        setUrl(window.location.href)
    }, [barSelected, lineSelected, view])
    //Update URL

    //This stores the scroll Y position on state
    useEffect(() => {
        const onScroll = (e) => {
            setScrollValue(e.target.documentElement.scrollTop)
        }
        window.addEventListener('scroll', onScroll)

        //Removes the event listener when the component gets unmounted
        return () => window.removeEventListener('scroll', onScroll)
    }, [scrollValue])

    useLayoutEffect(() => {
        //Every time the component re-renders, it scrolls automatically to the stored scroll Y position
        window.scrollTo(window.scrollX, scrollValue)
    })

    //Handlers
    const handleToggleSelection = (event) => {
        const newSelection = event.currentTarget.value
        if (newSelection === ToggleOptions.Time) {
            setBarchartMode(BarchartModes.Regular) //When we switch to the time view and get back to the comparison view we should start from the beginning
        }
        setView(newSelection)
        setBarSelected('')
        setLineSelected('')
    }

    const handleBarSelection = (selection) => {
        if (selection) {
            setBarSelected(selection)
            setBarchartMode(BarchartModes.Focused)
        }
    }
    const handleLineSelection = (selection) => {
        if (selection && selectOptions.includes(selection)) {
            setLineSelected(selection)
        }
    }
    const handleSelectOption = (event) => {
        const newSelection = event.target.value
        if (view === ToggleOptions.Comparison) {
            if (newSelection !== RESET_SELECT_LABEL) {
                setBarSelected(newSelection)
                setBarchartMode(BarchartModes.Focused)
            } else {
                setBarSelected('')
                setBarchartMode(BarchartModes.Regular)
            }
        } else {
            setLineSelected(newSelection)
        }
    }
    const handleCertificateAchieversCheckbox = () => {
        setCertificateAchieversCheckboxStatus(!certificateAchieversCheckboxStatus)
    }
    const handleFocusColorCommCheckbox = () => {
        setFocusColorCommCheckboxStatus(!focusColorCommCheckboxStatus)
    }

    const handleIndicatorNotes = () => {
        setShowIndicatorNotes(!showIndicatorNotes)
    }

    const handleOpenModalDialog = () => {
        setOpenModal(true)
    }

    const handleCloseModalDialog = () => {
        setOpenModal(false)
    }
    const handleDownloadImage = () => {
        setDownloadImage(true)
    }

    const handleCopyToClipboard = () => {
        setCopied(true)
    }

    //Customization
    let selectAvailable = false
    if (Comparison_against_population === 'TRUE' && view === ToggleOptions.Time && Indicator_code !== 'ed3') {
        selectAvailable = true
    } else if (Comparison_against_population !== 'TRUE' && Indicator_code !== 'sb3' && Indicator_code !== 'ao4') {
        selectAvailable = true
    } else if (view === ToggleOptions.Time && Indicator_code !== 'ao4' && Indicator_code !== 'ed3') {
        selectAvailable = true
    }

    const marginErrorAvailable = Margin_of_error === 'TRUE' ? true : false
    let barChartDataAvailable = barchartData
    if (barchartMode !== BarchartModes.Regular && barSelected && diffBarchartData.length) {
        barChartDataAvailable = diffBarchartData
    }

    const instructionsButtonStyle = { color: ButtonsColors.Instructions, border: `1px solid ${ButtonsColors.Instructions}`, backgroundColor: ButtonsColors.InstructionsBg, textTransform: 'none' }
    //Confidence intervals messages
    let legendLabel = ''
    if (view === ToggleOptions.Comparison || view === MigrationToggleOptions.InOut || view === MigrationToggleOptions.Net) {
        if (marginErrorAvailable && Indicator_code !== 'sb3') {
            legendLabel = ConfidenceIntervalMessage.Comparison
        } else if (view !== MigrationToggleOptions.Net) {
            legendLabel = ConfidenceIntervalMessage.ComparisonMigration
        } else legendLabel = ConfidenceIntervalMessage.Comparison
    } else {
        legendLabel = ConfidenceIntervalMessage.Time
    }

    const legendDisplay =
        ((view === ToggleOptions.Comparison || view === MigrationToggleOptions.InOut || view === MigrationToggleOptions.Net) && marginErrorAvailable) ||
        (view === ToggleOptions.Time && marginErrorAvailable)
            ? true
            : false
    const downloadButtonStyle = { color: ColorViz.Gray, textTransform: 'none', fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }
    const dataSourceLink = Data_Source___link ? (
        <a href={Data_Source___link} target="_blank">
            {Data_source}
        </a>
    ) : (
        Data_source
    )

    let selectOptions = view === ToggleOptions.Comparison ? barchartData.map((d) => d.childCategory) : _.uniq(lineChartData.map((d) => d.childCategory))
    selectOptions.sort() //Sort them alphabetically
    if (view === ToggleOptions.Comparison) {
        selectOptions.unshift(RESET_SELECT_LABEL)
    }
    const elementSelected = view === ToggleOptions.Comparison ? barSelected : lineSelected
    //Special cases for computed domain
    let barChartDomain = []
    if (Indicator_code === 'ig4' || focusColorCommCheckboxStatus || Indicator_code !== 'ao4') {
        const domain = computeDataDomain(barChartDataAvailable)
        barChartDomain = domain
    }

    let lineChartDomain = []
    if (lineChartData.length) {
        const marginError = Indicator_code !== 'sb3' ? marginErrorAvailable : false //Hack: net count within the migration rate does not have CI
        let min = marginError ? _.minBy(lineChartData, 'lowerValue')?.lowerValue : _.minBy(lineChartData, 'value')?.value
        let max = marginError ? _.maxBy(lineChartData, 'upperValue')?.upperValue : _.maxBy(lineChartData, 'value')?.value
        if (Indicator_code === 'ao4') {
            min = 0
        }
        lineChartDomain = [Math.floor(min), Math.ceil(max)]
    }

    let subtitle = view === ToggleOptions.Comparison || view === MigrationToggleOptions.InOut || view === MigrationToggleOptions.Net ? `${Geography}, ${Year___Point_in_time}` : Geography

    //Tooltips
    let tooltipAvailable = barchartMode === BarchartModes.Regular ? Primary_tooltip : NEW_re_focused_tooltip
    if (Comparison_against_population === 'TRUE') {
        tooltipAvailable = Comparison_tooltip
    }

    const linkButtonStyle = { color: ButtonsColors.Selected, border: `1px solid ${ButtonsColors.Selected}`, textTransform: 'none' }

    const toggleButtonsIconNames = Indicator_code !== 'sb3' ? [ToggleOptions.Comparison, ToggleOptions.Time] : [MigrationToggleOptions.InOut, MigrationToggleOptions.Net, ToggleOptions.Time]

    return (
        <div className="viz-container">
            {isClient ? (
                <>
                    <div className="viz-title">{Indicator_long_name}</div>
                    <div className="viz-subtitle">{subtitle}</div>
                    <div className="viz-instructions">
                        <SimpleButton variant={ButtonTypes.Outlined} label={'How to read this chart'} customization={instructionsButtonStyle} iconName="Question" onClick={handleOpenModalDialog} />
                    </div>
                    <div className="viz-controls">
                        <div>
                            {Time_series === 'TRUE' && (
                                <ToggleButtons
                                    buttons={Indicator_code !== 'sb3' ? TOGGLE_BUTTONS : TOGGLE_BUTTONS_MIGRATION}
                                    selection={view}
                                    onChange={handleToggleSelection}
                                    iconNames={toggleButtonsIconNames}
                                    orientation={Indicator_code === 'sb3' && isTabletWide ? 'vertical' : 'horizontal'}
                                />
                            )}
                        </div>

                        {Indicator_code === 'hc4' && view === ToggleOptions.Comparison && barchartMode !== BarchartModes.Focused && (
                            <div>
                                <SimpleCheckbox label="Add certificate achievers" checked={certificateAchieversCheckboxStatus} onChange={handleCertificateAchieversCheckbox} />
                            </div>
                        )}

                        {((Topic_area === Topics.BusinessDevelopment && Indicator_code !== 'ed3') || Indicator_code === 'demog' || Indicator_code === 'hc3') && (
                            <div>
                                <SimpleCheckbox label="Focus on communities of color" checked={focusColorCommCheckboxStatus} onChange={handleFocusColorCommCheckbox} />
                            </div>
                        )}

                        {selectAvailable && (
                            <div className="selector">
                                <BasicSelect optionsList={selectOptions} label="Select a community" selection={elementSelected} onChange={handleSelectOption} />
                            </div>
                        )}
                    </div>
                    <div className="inner-viz-container">
                        {isClient && view === ToggleOptions.Comparison && Indicator_code !== 'sb3' && (
                            <>
                                <BarChart
                                    mode={barchartMode}
                                    data={barChartDataAvailable}
                                    unit={Unit_of_measurement}
                                    errorBars={marginErrorAvailable}
                                    canClick={Indicator_code !== 'sb3' && Comparison_against_population === 'FALSE' ? true : false}
                                    barSelection={handleBarSelection}
                                    barSelected={barSelected}
                                    domain={barChartDomain}
                                    tooltip={tooltipAvailable}
                                    decimals={Decimals}
                                />
                            </>
                        )}
                        {isClient && (view === MigrationToggleOptions.InOut || view === MigrationToggleOptions.Net) && Indicator_code === 'sb3' && (
                            <>
                                <DivergingBarChart data={stackedBarchartData} unit={Unit_of_measurement} decimals={'one'} />
                            </>
                        )}
                        {isClient && view === ToggleOptions.Time && (
                            <>
                                <LineChart
                                    data={lineChartData}
                                    unit={Unit_of_measurement}
                                    domain={lineChartDomain}
                                    lineSelected={lineSelected}
                                    lineSelection={handleLineSelection}
                                    errorBars={marginErrorAvailable}
                                    decimals={Decimals}
                                    defaultLineSelected={defaultLineSelected}
                                    showZeroLine={Indicator_code === 'sb3'}
                                />
                            </>
                        )}
                    </div>
                    <div className="viz-footer">
                        <SimpleButton variant={ButtonTypes.Text} label={'Download image'} iconName={'Download'} iconOrder={'start'} customization={downloadButtonStyle} onClick={handleDownloadImage} />
                        {legendDisplay && <div className="legend-label">{legendLabel}</div>}
                    </div>
                    <div className="viz-misc">
                        <SimpleButton
                            variant={ButtonTypes.Text}
                            label={'Click to read notes on this indicator'}
                            iconName={showIndicatorNotes ? 'Remove' : 'Add'}
                            iconOrder={'start'}
                            customization={downloadButtonStyle}
                            onClick={handleIndicatorNotes}
                        />
                        <div className="source-label">Source: {dataSourceLink}</div>
                    </div>
                    {showIndicatorNotes && <div className="indicator-notes">{Indicator_notes}</div>}
                    <CopyToClipboard text={url} onCopy={() => handleCopyToClipboard()}>
                        <div className="share-button">
                            <SimpleButton variant={ButtonTypes.Outlined} label={'Copy link to this chart view to your clipboard'} customization={linkButtonStyle} iconName="Share" />
                        </div>
                    </CopyToClipboard>

                    <ModalDialog open={openModal} onClose={handleCloseModalDialog}>
                        <ChartInstructions view={view} onClose={handleCloseModalDialog} />
                    </ModalDialog>

                    {downloadImage && <ExportLayout />}
                </>
            ) : null}
        </div>
    )
}

VizContainer.propTypes = {
    metadata: PropTypes.object,
}

VizContainer.defaultProps = {
    metadata: null,
}

export default VizContainer
