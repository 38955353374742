import React, { useEffect, useRef } from 'react'
import { select } from 'd3-selection'
import { axisLeft, axisBottom, axisTop } from 'd3-axis'

import './axis.scss'

const Axis = ({ scale, position = 'top', offset = [0, 0], tickFormat = (d) => d, tickValues = [], tickCount = null }) => {
    const ref = useRef()
    useEffect(() => {
        if (scale.domain().some((d) => !!d)) {
            const axisFn = getAxisFn(position)
            const gElement = select(ref.current)
            const axisGenerator = axisFn(scale)
                .tickFormat(tickFormat)
                .tickValues(tickValues.length ? tickValues : null)
                .ticks(tickCount)

            gElement.call(axisGenerator)
        }
    }, [scale])

    return <g className="axis" ref={ref} transform={`translate(${offset[0]}, ${offset[1]})`} />
}

const getAxisFn = (position) => {
    switch (position) {
        case 'top':
            return axisTop
        case 'left':
            return axisLeft
        case 'bottom':
        default:
            return axisBottom
    }
}

export default Axis
