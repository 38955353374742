import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import { ButtonsColors } from '../../utils/theme'
import { ToggleOptions, MigrationToggleOptions, Breakpoint } from '../../utils/enum'

import ComparisonIconGray from '../../images/comparison-icon-gray.png'
import ComparisonIconWhite from '../../images/comparison-icon-white.png'
import TimeIconGray from '../../images/time-icon-gray.png'
import TimeIconWhite from '../../images/time-icon-white.png'

import './toggle-buttons.scss'

//SSR and MUI style customization does not work so well
//sx prop seems to be the option for now
const style = [
    { fontFamily: 'Lato', fontWeight: 'bold', textTransform: 'none', backgroundColor: ButtonsColors.Unselected, whiteSpace: 'nowrap' },
    {
        '&.Mui-selected': {
            color: '#FFFFFF',
            backgroundColor: ButtonsColors.Selected,
        },
        '&:hover': { backgroundColor: ButtonsColors.UnselectedHovered },
        '&.Mui-selected:hover': { backgroundColor: ButtonsColors.SelectedHovered },
    },
]

const ToggleButtons = ({ buttons, selection, onChange, iconNames, orientation = 'horizontal' }) => {
    const isTabletWide = useMediaQuery(`(max-width:${Breakpoint.TabletWide}px)`)

    const toggleButtons = buttons.map((button, idx) => {
        return (
            <ToggleButton value={button.id} key={idx} className="toggleButton" sx={style}>
                {iconNames?.length > 0 && !isTabletWide && <img src={iconPath(iconNames[idx], selection)} alt={iconNames[idx]} />}
                {button.label}
            </ToggleButton>
        )
    })

    return (
        <ToggleButtonGroup size="small" value={selection} exclusive onChange={onChange} orientation={orientation}>
            {toggleButtons}
        </ToggleButtonGroup>
    )
}

export default ToggleButtons

const iconPath = (label, selection) => {
    if (
        (label === ToggleOptions.Comparison && selection === ToggleOptions.Comparison) ||
        (label === MigrationToggleOptions.InOut && selection === MigrationToggleOptions.InOut) ||
        (label === MigrationToggleOptions.Net && selection === MigrationToggleOptions.Net)
    ) {
        return ComparisonIconWhite
    } else if (
        (label === ToggleOptions.Comparison && selection !== ToggleOptions.Comparison) ||
        (label === MigrationToggleOptions.InOut && selection !== MigrationToggleOptions.InOut) ||
        (label === MigrationToggleOptions.Net && selection !== MigrationToggleOptions.Net)
    ) {
        return ComparisonIconGray
    } else if (label === ToggleOptions.Time && selection === ToggleOptions.Time) {
        return TimeIconWhite
    } else return TimeIconGray
}
